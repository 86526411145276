import Layout from "../components/Layout";
import React from "react";

const NotFoundPage = () => {
    return (
        <Layout>
            <p>Not found 404</p>
        </Layout>
    )
}

export default NotFoundPage;